.todo {
  .theme-switch {
    @apply w-10 h-10 fixed bottom-2 left-2 cursor-pointer transition-all
    text-slate-600 dark:text-gray-100 z-20;
    &::before {
      @apply w-0 h-0 absolute content-[''] -bottom-2 -left-2 origin-bottom-left cursor-pointer transition-all rounded-se-full
      bg-slate-600 dark:bg-gray-100 -z-10;
    }

    &:hover {
      @apply scale-110 text-gray-100 dark:text-slate-600;
    }

    &:hover::before {
      @apply w-16 h-16;
    }
  }
}
