.not-found {
  @apply w-screen h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-slate-600;
  &__404 {
    @apply mb-10 text-9xl font-bold text-slate-700 dark:text-gray-200 animate-bounce;
  }

  &__text {
    @apply mb-24 text-5xl font-bold text-slate-800 dark:text-gray-400;
  }

  .my-button {
    @apply py-3 px-8 bg-red-500 text-lg font-bold tracking-wider rounded-2xl dark:text-gray-100;
    &:not(:disabled):hover {
      @apply bg-red-600;
    }
  }
}