.todo {
  &__lists {
    $self: &;
    @apply mb-10;
    .icon {
      @apply dark:text-white;
    }

    &-item {
      @apply flex items-center mb-4 px-4 py-1.5 rounded-lg transition-all cursor-pointer bg-transparent;

      &__text {
        @apply ms-6 w-56 h-10 flex-1 inline-block leading-10 truncate dark:text-gray-100;
      }

      &:hover {
        #{$self}-item__text {
          @apply text-clip;
        }

        #{$self}__remove-icon {
          @apply opacity-50;
        }
      }

      #{$self}__remove-icon {
        @apply w-6 h-6 items-center bg-transparent opacity-0 transition-all dark:text-gray-50;
        &:hover {
          @apply opacity-90;
        }

        &:active {
          @apply scale-75;
        }
      }

      &:hover {
        @apply bg-gray-50
        dark:bg-slate-700;
      }

      &.active {
        @apply bg-white shadow-md
        dark:bg-slate-700;
      }

      &:not(.active):active {
        @apply scale-95;
      }
    }
  }
}