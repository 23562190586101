.todo {
  .add-list {
    @apply bg-transparent;
    &-button {
      @apply opacity-50 flex items-center px-4 py-1.5 rounded-lg transition-all cursor-pointer;
      &:hover {
        @apply opacity-90;
      }
      &:active {
        @apply scale-95;
      }
    }

    &__popup {
      @apply px-10 py-8 relative min-w-max -top-5 left-7 z-10 bg-white dark:bg-slate-700 rounded-lg drop-shadow-lg;
      &-close-button {
        @apply w-10 h-10 absolute -top-3 -right-3 cursor-pointer transition-all text-slate-600 dark:text-slate-700 dark:fill-slate-500;
        &:hover {
          @apply scale-105;
        }
        &:active {
          @apply scale-95;
        }
      }

      &-color-picker {
        @apply mb-2 flex flex-wrap justify-center gap-3;
        .badge {
          @apply mb-3 w-6 h-6 border-2 border-transparent transition-all cursor-pointer;
          &:hover, &.active {
            @apply border-2 border-gray-600 dark:border-slate-800;
          }
          &.active {
            @apply border-gray-700 dark:border-slate-800 scale-125;
          }
        }
      }

      .my-button {
        @apply mt-4 w-full ;
      }
    }
  }
}
