.todo,
.home,
.not-found {
  .my-button {
    @apply shadow-md bg-slate-500 text-gray-50 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center transition-all;
    &:not(:disabled):hover {
      @apply bg-slate-600 ;
    }

    &:not(:disabled):active {
      @apply scale-95 shadow-sm;
    }

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }
}
